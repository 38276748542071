import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { SobannerProps } from '../../models';

const PageContainerVariant: FC<SobannerProps> = ({
  data: { title, subtitle, extraClass, containerText, legalText, imagePage },
}) => (
  <div className={`soBanner soBanner__container soBanner__container--page ${extraClass || ''}`}>
    {title ? <h2 className="soBanner__title">{title}</h2> : <></>}
    {subtitle ? <h3 className="soBanner__subtitle">{subtitle}</h3> : <></>}
    <div className="soBanner__info">
      {containerText ? (
        <div className="soBanner__text">
          <DangerouslySetInnerHtml html={containerText as string} />
        </div>
      ) : (
        <></>
      )}
      {imagePage && imagePage ? (
        <div className="soBanner__image">
          <Image
            imageData={imagePage}
            alt={imagePage.altText ? imagePage.altText : ''}
            data-testid="banner-image"
            className="image"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
    {legalText ? (
      <div className="soBanner__legal-text text-center small">
        <DangerouslySetInnerHtml html={legalText as string} />
      </div>
    ) : (
      <></>
    )}
  </div>
);

export default PageContainerVariant;
