import React, { FC } from 'react';

import Image from 'common/Image';

import { SoBannerItemProps } from '../../../models';

const ImageRecipe: FC<SoBannerItemProps> = ({ data: { imageRecipe } }) => (
  <div className="recipe__info">
    {imageRecipe && imageRecipe.desktop ? (
      <Image
        imageData={imageRecipe}
        alt={imageRecipe.altText ? imageRecipe.altText : ''}
        data-testid="recipe-image"
        className="recipe__image"
      />
    ) : (
      <></>
    )}
  </div>
);

export default ImageRecipe;
