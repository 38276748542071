import React, { FC } from 'react';

import { SplashItemProps } from './models';

const SplashLoader: FC<SplashItemProps> = ({
  data: { extraClass, videoBanner, videoBannerMobile },
  index,
  setClass,
}) => {
  const handleVideoEnd = () => {
    if (setClass !== undefined) setClass(true);
  };

  return (
    <div
      className={`banner__item banner__item--text-variant banner__item--${extraClass || ''}`}
      key={`sobannerItemText_${index}`}
    >
      {videoBannerMobile?.url ? (
        <video
          className="d-md-none banner__item-video--mobile"
          autoPlay
          muted
          onEnded={handleVideoEnd}
        >
          <source src={videoBannerMobile?.url} type="video/mp4" />
        </video>
      ) : (
        <></>
      )}
      {videoBanner?.url ? (
        <video
          className="d-none d-md-block banner__item-video--desktop"
          autoPlay
          muted
          onEnded={handleVideoEnd}
        >
          <source src={videoBanner?.url} type="video/mp4" />
        </video>
      ) : (
        <></>
      )}
    </div>
  );
};
export default SplashLoader;
