import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { SoBannerItemProps } from '../../models';
import { ImageRecipe, ImageRecipeWithLink } from './snippets';

const RecipeVariant: FC<SoBannerItemProps> = ({ data, index }) => {
  const { extraClass, bannerText, button } = data;

  return (
    <div
      className={`banner__item banner__variant--recipe banner__item--${extraClass || ''}`}
      key={`sobannerItemRecipe_${index}`}
    >
      {bannerText ? <DangerouslySetInnerHtml html={bannerText as string} /> : <></>}
      {button ? (
        <ImageRecipeWithLink data={data} index={index} />
      ) : (
        <ImageRecipe data={data} index={index} />
      )}
    </div>
  );
};

export default RecipeVariant;
