import React, { FC, useRef } from 'react';

import { ScrollPopupEffectProps } from './models';

import './ScrollPopupEffect.scss';

const ScrollPopupEffect: FC<ScrollPopupEffectProps> = ({ children }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('scroll', () => {
      if (
        elementRef &&
        elementRef.current &&
        elementRef.current.getBoundingClientRect().top < window.scrollY + window.innerHeight / 4
      ) {
        elementRef.current.classList.add('active');
      } else if (
        elementRef &&
        elementRef.current &&
        elementRef.current.getBoundingClientRect().top > window.scrollY + window.innerHeight
      ) {
        elementRef.current.classList.remove('active');
      }
    });
  }

  return (
    <div className="ScrollPopupEffect" ref={elementRef}>
      {children}
    </div>
  );
};
export default ScrollPopupEffect;
