import React, { FC } from 'react';

import { SobannerProps } from './models';
import {
  DefaultContainerVariant,
  GridContainerVariant,
  PageContainerVariant,
  PopupContainerVariant,
  SliderContainerVariant,
  SplashContainerVariant,
  VideoContainerVariant,
} from './variants/containers';

import './SoBanner.scss';

const SoBanner: FC<SobannerProps> = ({ data }) => {
  const { variant } = data;
  switch (variant) {
    case 'grid':
      return <GridContainerVariant data={data} />;
    case 'page':
      return <PageContainerVariant data={data} />;
    case 'popup':
      return <PopupContainerVariant data={data} />;
    case 'slider':
      return <SliderContainerVariant data={data} />;
    case 'video':
      return <VideoContainerVariant data={data} />;
    case 'splash':
      return <SplashContainerVariant data={data} />;
    default:
      return <DefaultContainerVariant data={data} />;
  }
};

export default SoBanner;
