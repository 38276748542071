import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { SoBannerItemProps } from '../../models';

const ProductVariant: FC<SoBannerItemProps> = ({
  data: { extraClass, legalText, bannerText, button, buttonType, imageProduct },
  index,
}) => (
  <div
    className={`banner__item banner__variant--product banner__item--${
      extraClass || ''
    } row align-items-center mx-auto`}
    key={`sobannerItemProduct_${index}`}
  >
    <div className="product__info col-12 col-md-6 col-xl-5">
      {bannerText ? <DangerouslySetInnerHtml html={bannerText as string} /> : <></>}
      {button ? (
        <a href={button.url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
          {button.name}
        </a>
      ) : (
        <></>
      )}
    </div>
    <div className="product__image col-12 col-md-6 col-xl-7">
      {imageProduct && imageProduct.desktop ? (
        <Image
          imageData={imageProduct}
          alt={imageProduct.altText ? imageProduct.altText : ''}
          data-testid="product-image"
          className="product__image"
        />
      ) : (
        <></>
      )}
    </div>
    {legalText ? (
      <div className="product__legal-text col-12 col-md-6 col-xl-7 order-3">
        <DangerouslySetInnerHtml html={legalText as string} />
      </div>
    ) : (
      <></>
    )}
  </div>
);

export default ProductVariant;
