import React, { FC } from 'react';

import Image from 'common/Image';

import { SoBannerItemProps } from '../../../models';

const ImageRecipeWithLink: FC<SoBannerItemProps> = ({
  data: { button, buttonType, imageRecipe },
}) => (
  <div className="recipe__info">
    <a href={button.url} className={`recipe__link ${buttonType ? `btn--${buttonType}` : ''}`}>
      {imageRecipe && imageRecipe.desktop ? (
        <Image
          imageData={imageRecipe}
          alt={imageRecipe.altText ? imageRecipe.altText : ''}
          data-testid="recipe-image"
          className="recipe__image"
        />
      ) : (
        <></>
      )}
      <strong className="recipe__title">{button.name}</strong>
    </a>
  </div>
);

export default ImageRecipeWithLink;
