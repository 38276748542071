import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { SoBannerItemProps } from '../../models';

const TextVariant: FC<SoBannerItemProps> = ({
  data: {
    imageDesktop,
    imageMobile,
    extraClass,
    legalText,
    bannerText,
    button,
    buttonType,
    videoBanner,
    videoBannerMobile,
  },
  index,
}) => (
  <div
    className={`banner__item banner__item--text-variant banner__item--${extraClass || ''}`}
    key={`sobannerItemText_${index}`}
  >
    {videoBannerMobile?.url ? (
      <video className="d-md-none banner__item-video--mobile" autoPlay muted loop playsInline>
        <source src={videoBannerMobile?.url} type="video/mp4" />
      </video>
    ) : (
      <></>
    )}
    {videoBanner?.url ? (
      <video
        className="d-none d-md-block banner__item-video--desktop"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={videoBanner?.url} type="video/mp4" />
      </video>
    ) : (
      <></>
    )}
    <div className="d-none d-md-block videoPoster">
      {imageDesktop && imageDesktop.desktop ? (
        <Image
          imageData={imageDesktop}
          alt={imageDesktop.altText ? imageDesktop.altText : ''}
          data-testid="banner-image-desktop"
          className="banner__image--desktop"
        />
      ) : (
        <></>
      )}
    </div>
    <div className="d-block d-md-none videoPoster">
      {imageMobile && imageMobile.desktop ? (
        <Image
          imageData={imageMobile}
          alt={imageMobile.altText ? imageMobile.altText : ''}
          data-testid="banner-image-mobile"
          className="banner__image--mobile"
        />
      ) : (
        <></>
      )}
    </div>
    <div className="banner__info">
      <div className="banner__info-content">
        {bannerText ? <DangerouslySetInnerHtml html={bannerText as string} /> : <></>}
        {button ? (
          <a href={button.url} className={`btn ${buttonType ? `btn--${buttonType}` : ''}`}>
            {button.name}
          </a>
        ) : (
          <></>
        )}
      </div>
      {legalText ? (
        <div className="legal-text">
          <DangerouslySetInnerHtml html={legalText as string} />
        </div>
      ) : (
        <></>
      )}
    </div>
  </div>
);

export default TextVariant;
