import React, { FC, useEffect, useState } from 'react';

import { SplashLoader } from '../../../SplashLoader';
import { SoBannerItem, SobannerProps } from '../../models';

const DefaultContainerVariant: FC<SobannerProps> = ({ data: { extraClass, items, time } }) => {
  const [animationClass, setClass] = useState(false);
  const [renderSplash, setRender] = useState(true);

  useEffect(() => {
    if (time !== undefined) {
      setTimeout(() => {
        setRender(false);
      }, time * 1000 + 500);
    }
    if (animationClass) {
      if (time !== undefined) {
        setTimeout(() => {
          setRender(false);
        }, time * 1000);

        return;
      }
      setRender(false);
    }
  }, [animationClass, time]);

  return (
    <>
      {renderSplash ? (
        <div
          className={`soBanner soBanner__container soBanner__container--splash ${
            extraClass || ''
          } ${animationClass ? 'animation-class' : 'no-animation'}`}
        >
          {items.map((item: SoBannerItem, index: number) => (
            <SplashLoader data={item} index={index} setClass={setClass} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DefaultContainerVariant;
