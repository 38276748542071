import React, { FC } from 'react';

import Image from 'common/Image';

import { SoBannerItemProps } from '../../models';

const TextVariant: FC<SoBannerItemProps> = ({
  data: { imageDesktop, imageMobile, extraClass, button },
  index,
}) => (
  <div
    className={`banner__item banner__item--text-variant ${extraClass || ''}`}
    key={`sobannerItemImage_${index}`}
  >
    {button ? (
      <a href={button.url}>
        <div className="d-none d-md-block">
          {imageDesktop ? (
            <Image
              imageData={imageDesktop}
              alt={imageDesktop.altText ? imageDesktop.altText : ''}
              data-testid="banner-image-desktop"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="d-block d-md-none">
          {imageMobile ? (
            <Image
              imageData={imageMobile}
              alt={imageMobile.altText ? imageMobile.altText : ''}
              data-testid="banner-image-desktop"
            />
          ) : (
            <></>
          )}
        </div>
      </a>
    ) : (
      <>
        <div className="d-none d-md-block">
          <Image
            imageData={imageDesktop}
            alt={imageDesktop.altText ? imageDesktop.altText : ''}
            data-testid="banner-image-desktop"
          />
        </div>
        <div className="d-block d-md-none">
          <Image
            imageData={imageMobile}
            alt={imageMobile.altText ? imageMobile.altText : ''}
            data-testid="banner-image-desktop"
          />
        </div>
      </>
    )}
  </div>
);

export default TextVariant;
