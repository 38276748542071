import React, { FC } from 'react';

import { SoBannerItem, SoBannerPageProps } from '../../../models';
import {
  ImageVariant,
  ProductVariant,
  RecipeVariant,
  TextRightVariant,
  TextVariant,
} from '../../items';

const SliderPage: FC<SoBannerPageProps> = ({ items, indexPage }) => (
  <div className="SliderPage" key={indexPage}>
    {items.map((item: SoBannerItem, index: number) => {
      const { variant } = item;
      const IndexKey = index + Date.now();
      switch (variant) {
        case 'text':
          return <TextVariant data={item} index={index} key={IndexKey} />;
        case 'textRight':
          return <TextRightVariant data={item} index={index} key={IndexKey} />;
        case 'product':
          return <ProductVariant data={item} index={index} key={IndexKey} />;
        case 'recipe':
          return <RecipeVariant data={item} index={index} key={IndexKey} />;
        case 'image':
          return <ImageVariant data={item} index={index} key={IndexKey} />;
        default:
          return <React.Fragment key={IndexKey}>{variant}</React.Fragment>;
      }
    })}
  </div>
);

export default SliderPage;
