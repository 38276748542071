import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { SoBannerItem, SobannerProps } from '../../models';
import {
  CardVariant,
  ImageVariant,
  ProductVariant,
  RecipeVariant,
  TextRightVariant,
  TextVariant,
} from '../items';

const GridContainerVariant: FC<SobannerProps> = ({
  data: { title, subtitle, extraClass, containerText, items, legalText },
}) => (
  <div className={`soBanner soBanner__container soBanner__container--grid ${extraClass || ''}`}>
    {title ? <h2 className="soBanner__title">{title}</h2> : <></>}

    {subtitle ? <h3 className="soBanner__subtitle">{subtitle}</h3> : <></>}

    <div className="grid">
      {items.map((item: SoBannerItem, index: number) => {
        const { variant } = item;
        const indexKey = index + Date.now();
        switch (variant) {
          case 'text':
            return <TextVariant data={item} index={index} key={indexKey} />;
          case 'textRight':
            return <TextRightVariant data={item} index={index} key={indexKey} />;
          case 'product':
            return <ProductVariant data={item} index={index} key={indexKey} />;
          case 'recipe':
            return <RecipeVariant data={item} index={index} key={indexKey} />;
          case 'image':
            return <ImageVariant data={item} index={index} key={indexKey} />;
          case 'card':
            return <CardVariant data={item} index={index} key={indexKey} />;
          default:
            return <React.Fragment key={indexKey}>{variant}</React.Fragment>;
        }
      })}
    </div>
    {containerText ? (
      <DangerouslySetInnerHtml className="grid__text" html={containerText as string} />
    ) : (
      <></>
    )}
    {legalText ? (
      <div className="soBanner__legal-text text-center small">
        <DangerouslySetInnerHtml html={legalText as string} />
      </div>
    ) : (
      <></>
    )}
  </div>
);

export default GridContainerVariant;
