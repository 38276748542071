import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { SoBannerItem, SobannerProps } from '../../models';
import {
  ImageVariant,
  ProductVariant,
  RecipeVariant,
  TextRightVariant,
  TextVariant,
  VideoVariant,
} from '../items';

const DefaultContainerVariant: FC<SobannerProps> = ({
  data: { title, extraClass, items, showPager, showThumbs, showArrows, showStatus, infiniteLoop },
}) => (
  <div className={`soBanner soBanner__container soBanner__container--video ${extraClass || ''}`}>
    {title ? <h2 className="soBanner__title">{title}</h2> : <></>}

    <Carousel
      showThumbs={showThumbs === 'yes'}
      showIndicators={showPager === 'yes'}
      showArrows={showArrows === 'yes'}
      showStatus={showStatus === 'yes'}
      infiniteLoop={infiniteLoop === 'yes'}
    >
      {items.map((item: SoBannerItem, index: number) => {
        const { variant } = item;
        const indexKey = index + Date.now();
        switch (variant) {
          case 'text':
            return <TextVariant data={item} index={index} key={indexKey} />;
          case 'textRight':
            return <TextRightVariant data={item} index={index} key={indexKey} />;
          case 'product':
            return <ProductVariant data={item} index={index} key={indexKey} />;
          case 'recipe':
            return <RecipeVariant data={item} index={index} key={indexKey} />;
          case 'image':
            return <ImageVariant data={item} index={index} key={indexKey} />;
          case 'video':
            return <VideoVariant data={item} index={index} key={indexKey} />;
          default:
            return <React.Fragment key={indexKey}>{variant}</React.Fragment>;
        }
      })}
    </Carousel>
  </div>
);

export default DefaultContainerVariant;
