import React, { FC } from 'react';
import Modal from 'react-modal';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { SobannerProps } from '../../models';

const PageContainerVariant: FC<SobannerProps> = ({
  data: { title, subtitle, extraClass, containerText, legalText, imagePage, imageButtonPopup },
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <button
        className="btn btn--no-arrow soBanner__popup-button--open"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {imageButtonPopup?.url && imageButtonPopup?.altText ? (
          <img src={imageButtonPopup.url} alt={imageButtonPopup.altText} />
        ) : (
          'Open modal'
        )}
      </button>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)}>
        <div className="soBanner__popup">
          <div className={`soBanner__popup-container ${extraClass || ''}`}>
            <button
              className="btn btn--no-arrow soBanner__popup-button--close"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              x
            </button>
            {title ? <h2 className="soBanner__title">{title}</h2> : <></>}
            {subtitle ? <h3 className="soBanner__subtitle">{subtitle}</h3> : <></>}
            <div className="soBanner__info">
              {containerText ? (
                <div className="soBanner__text">
                  <DangerouslySetInnerHtml html={containerText as string} />
                </div>
              ) : (
                <></>
              )}
              {imagePage && imagePage ? (
                <div className="soBanner__image">
                  <Image
                    imageData={imagePage}
                    alt={imagePage.altText ? imagePage.altText : ''}
                    data-testid="banner-image"
                    className="image"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {legalText ? (
              <div className="soBanner__legal-text text-center small">
                <DangerouslySetInnerHtml html={legalText as string} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PageContainerVariant;
